<template>
  <div>
    <breadCrumb :title="$t('fu-shu-xin-xi')" :menuList="menuList"></breadCrumb>

   <section class="pt-4 mb-4">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <form id="shop" class="" >
              <div class="bg-white rounded shadow-sm mb-3">
                <div class="fs-15 fw-600 p-3 border-bottom">
                  {{ $t('yong-hu-xin-xi') }} </div>
                <div class="p-3">
                  <div class="row">
                    <label class="col-md-2 col-form-label">{{ $t('xing-ming-0') }} <span class="text-primary">*</span></label>
                    <div class="col-md-10">
                      <div class="mb-3">
                        <input type="text" class="form-control" v-model="form.username" :placeholder="$t('xing-ming-0')" name="name" required>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-md-2 col-form-label">{{ $t('dian-zi-you-jian') }} <span class="text-primary">*</span></label>
                    <div class="col-md-10">
                      <div class="mb-3">
                        <input type="text" class="form-control" v-model="form.email" :placeholder="$t('dian-zi-you-jian')" name="name" required>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-md-2 col-form-label">{{ $t('mi-ma') }} <span class="text-primary">*</span></label>
                    <div class="col-md-10">
                      <div class="mb-3">
                        <input type="password" class="form-control" v-model="form.password" :placeholder="$t('mi-ma')" name="name" required>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-md-2 col-form-label">{{ $t('que-ren-mi-ma-0') }} <span class="text-primary">*</span></label>
                    <div class="col-md-10">
                      <div class="mb-3">
                        <input type="password" class="form-control" v-model="form.password2" :placeholder="$t('que-ren-mi-ma-0')" name="name" required>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bg-white rounded shadow-sm mb-4">
                <div class="fs-15 fw-600 p-3 border-bottom">
                  {{ $t('yan-zheng-xin-xi') }} </div>
                <div class="p-3">
                  <div class="row">
                    <label class="col-md-2 col-form-label">{{ $t('xing-ming-0') }} <span class="text-primary">*</span></label>
                    <div class="col-md-10">
                      <div class="mb-3">
                        <input type="text" class="form-control" v-model="form.username" :placeholder="$t('xing-ming-0')" name="name" required>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-md-2 col-form-label">{{ $t('dian-zi-you-jian') }} <span class="text-primary">*</span></label>
                    <div class="col-md-10">
                      <div class="mb-3">
                        <input type="text" class="form-control" v-model="form.email" :placeholder="$t('dian-zi-you-jian')" name="name" required>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-md-2 col-form-label">{{ $t('di-zhi-3') }} <span class="text-primary">*</span></label>
                    <div class="col-md-10">
                      <div class="mb-3">
                        <input type="text" class="form-control" v-model="form.address" :placeholder="$t('di-zhi-3')" name="name" required>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-md-2 col-form-label">{{ $t('dian-hua') }} <span class="text-primary">*</span></label>
                    <div class="col-md-10">
                      <div class="mb-3">
                        <input type="text" class="form-control" v-model="form.phone" :placeholder="$t('dian-hua')" name="name" required>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-md-2 col-form-label">{{ $t('ni-jiang-ru-he-jia-ru') }} <span class="text-primary">*</span></label>
                    <div class="col-md-10">
                      <div class="mb-3">
                        <input type="text" class="form-control" v-model="form.shopName" :placeholder="$t('ni-jiang-ru-he-jia-ru-0')" name="name" required>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right">
                <button type="submit" class="btn btn-primary fw-600">{{ $t('bao-cun') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: this.$t('fu-shu-gong-si'),
          path: ''
        },
      ],
      form: {
        username: '',
        email: '',
        password: '',
        password2: '',
        shopName: '',
        address: '',
        type: '',
        filename1: '',
        filename2: ''
      },
      typeList: [
        {
          value: 1,
          name: this.$t('shen-fen-zheng')
        },
        {
          value: 2,
          name: this.$t('hu-zhao')
        },
      ],
      fileType: '',
      fileList1: [], 
      fileList2: [] 
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    addFile(type) {
      this.fileType = type
      document.getElementById('fileInput').click()
    },
    uploadFile() {

    }
  }
}
</script>